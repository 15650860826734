import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-case-study4',
  templateUrl: './case-study4.component.html',
  styleUrls: ['./case-study4.component.css']
})
export class CaseStudy4Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: CaseStudy4Component) {  
  context.title = 'Application Management Service (AMS) Case Study | Skillikz';
  context.description = 'Explore how Skillikz helped a medium-sized financial enterprise modernize its IT infrastructure and streamline operations through Application Management Services (AMS). Discover how we reduced costs, mitigated risks, and improved productivity.';
  context.keywords = 'Application Management Service, AMS, Case study, Financial sector, IT infrastructure modernization, Cost reduction, Risk mitigation, Productivity improvement, Skillikz, ITIL standards, Service Integration and Management, Root-cause analysis, Knowledge management, Preventive maintenance, 24/7 support';
  context.seoTags();
}