import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-ui-engineers-reactjs-nodejs',
  templateUrl: './ui-engineers-reactjs-nodejs.component.html',
  styleUrls: ['./ui-engineers-reactjs-nodejs.component.css']
})

export class UiEngineersReactjsNodejsComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: UiEngineersReactjsNodejsComponent) {  
  context.title = 'UI Engineers - ReactJS, NodeJS Vacancy at Skillikz - Redefining Tech & Business Services';
  context.description = `We are looking for UI Engineers to join our team, who will work closely with development team to develop new system capabilities as defined within the technical/ functional specifications.`;
  context.keywords = 'Skillikz, UI Engineers, ReactJS, NodeJS, Frontend Development, Web Development, User Interface, User Experience, JavaScript, HTML, CSS, Responsive Design, Single-Page Applications, Component-Based Development, Redux, RESTful APIs, GraphQL, Web Standards, Cross-Browser Compatibility, Performance Optimization, Testing and Debugging, Git, Agile Development, Scrum, Software Engineering, Code Review, Continuous Integration, Deployment, Collaborative Team, Problem Solving, Communication Skills, Time Management, Full-time, Part-time, Remote, On-site, Job Opportunities, Hiring, Website Development, Tech Industry, IT Jobs';
  context.seoTags();
}
