import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-java-developer2',
  templateUrl: './java-developer2.component.html',
  styleUrls: ['./java-developer2.component.css']
})
export class JavaDeveloper2Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: JavaDeveloper2Component) {  
  context.title = 'Java Developer - SKILLIKZ';
  context.description = 'We are looking for a Java Developer with 2-3 years of core development experience.';
  context.keywords = 'Software development services London, Software development services UK, Software development services US, Outsourced IT Services London, Outsourced IT Services UK, Outsourced IT Services US, Digital Transformation, Software services, Microsoft services, Salesforce Services, Digital Strategy, IT Strategy, Managed Services, IT Consultants London, Technology Consultants London, Managed IT Services, Digital Compliance, Financial Compliance, IT Audit Services, IT Engineering Services';
  context.seoTags();
}
