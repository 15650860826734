import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-financial-compliance',
  templateUrl: './financial-compliance.component.html',
  styleUrls: ['./financial-compliance.component.css']
})
export class FinancialComplianceComponent extends BaseClass implements OnInit {

  constructor(public router: ActivatedRoute, private location: Location) { super() }

  ngOnInit(): void {
    init(this);
    this.showLocationTab(null, 'tabOne');
    this.router.queryParams.subscribe(params => {
      if (params && params['tabName']) {
        this.showLocationTab(null, params['tabName']);
        this.location.replaceState('/financial-compliance');
      }
    });
  }
  override ngAfterViewInit(): void {
    //function Hidden courses here
  }
  buybundels(){
    const url = 'https://lms.skillikz.com/catalog/index/group:19';
    window.open(url, '_blank');
  }
}

function init(context: FinancialComplianceComponent) {
  context.title = 'Financial Compliance | Skillikz Ltd';
  context.description = 'Enhance business integrity with our CPD accredited modules. Combat economic crime with AML, Anti-Bribery, Prevent Duty, and more. Flexible learning, Learn 24x7, intuitive interface, interactive scenarios, and immediate practical application, CPD accredited.';
  context.keywords = 'Skillikz, Anti-Bribery Level 2 & 3, Anti Money Laundering, Prevent Duty, Conflict Resolution, Data Protection and GDPR level 3, GDPR Awareness Level 2, Cyber Security';
  context.seoTags();
}
