import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-blogs-details-b19423',
  templateUrl: './blogs-details-b19423.component.html',
  styleUrls: ['./blogs-details-b19423.component.css']
})
export class BlogsDetailsB19423Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetailsB19423Component) {  
  context.title = 'Navigating Regulatory Compliance: Overcoming Business Challenges and Training Employees on Compliance | Skillikz Blog';
  context.description = "Discover the importance of regulatory compliance for businesses and learn how to navigate the challenges it presents. Explore the Skillikz blog for insights on training employees to ensure compliance and safeguard your organization's vitality.";
  context.keywords = 'regulatory compliance, business challenges, training employees, compliance training, Skillikz blog, importance of compliance, navigating compliance, safeguarding organizations, business vitality';
  context.seoTags();
}
