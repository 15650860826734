import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-blogs-details20',
  templateUrl: './blogs-details20.component.html',
  styleUrls: ['./blogs-details20.component.css']
})
export class BlogsDetails20Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails20Component) {  
  context.title = 'Latest Practices in IT Product Development and Testing | Trends to Watch';
  context.description = 'Stay ahead of the competition with the latest trends in IT product development and testing. From Agile and DevOps to AI, ML, and test automation, learn how to deliver high-quality products quickly and efficiently';
  context.keywords = 'IT product development, IT product testing, Agile, DevOps, artificial intelligence, machine learning, cloud-based testing, shift-left testing, test automation, continuous integration and delivery, testing as a service, software development, software testing';
  context.seoTags();
}
