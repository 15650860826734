import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-blogs-details5',
  templateUrl: './blogs-details5.component.html',
  styleUrls: ['./blogs-details5.component.css']
})
export class BlogsDetails5Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails5Component) {  
  context.title = 'Applications of Hybrid-Cloud in Business Processes | Benefits & Uses';
  context.description = "Learn about the benefits and uses of hybrid cloud in business processes, including increased flexibility, cost savings, improved security, disaster recovery, and big data applications. Discover how hybrid cloud can help businesses improve their processes, reduce costs, and stay competitive in today's rapidly changing business environment.";
  context.keywords = 'hybrid cloud, business processes, flexibility, scalability, cost savings, improved security, disaster recovery, big data, analytics, private cloud, public cloud, IT infrastructure, data breaches, compliance certifications';
  context.seoTags();
}
