import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-big-data-consultant',
  templateUrl: './big-data-consultant.component.html',
  styleUrls: ['./big-data-consultant.component.css']
})
export class BigDataConsultantComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BigDataConsultantComponent) {  
  context.title = 'Big Data Consultant Vacancy at Skillikz - Redefining Tech & Business Services';
  context.description = `Join Skillikz as a Big Data Consultant. Join hands-on projects, mentor a dynamic team, and engage in high-level client discussions. 7+ years of Informatica tools experience required.
  Elevate your career with us!`;
  context.keywords = 'Skillikz ,Big Data Consultant, Think Big initiative, Informatica tools, ETL/MDM/DQ, MR1 YARN applications, Java, Scala, Python, Hive and Impala scripts, Business Intelligence, Business Analytics, Global environment, Financial data, Equal opportunity employer, Career growth, Technical mastery, Business Digital Transformation';
  context.seoTags();
}