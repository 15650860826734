import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-case-study1',
  templateUrl: './case-study1.component.html',
  styleUrls: ['./case-study1.component.css']
})
export class CaseStudy1Component extends BaseClass implements OnInit {
  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }
}

function init(context: CaseStudy1Component) {  
  context.title = 'Business Digital Transformation Case Study | Skillikz';
  context.description = 'Explore our successful business digital transformation case study for a leading mortgage firm in the USA. Discover how Skillikz streamlined loan processing, reduced costs, and improved customer satisfaction through cutting-edge technology.';
  context.keywords = 'Business digital transformation, Case study, Mortgage industry, Loan processing, Customer satisfaction, Technology architecture, Governance framework, Automation, Infrastructure cost, Quick decision-making, Mortgage industry solutions, Skillikz';
  context.seoTags();
}