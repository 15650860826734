import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-case-study6',
  templateUrl: './case-study6.component.html',
  styleUrls: ['./case-study6.component.css']
})
export class CaseStudy6Component extends BaseClass implements OnInit {
  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: CaseStudy6Component) {  
  context.title = 'Cyber Security Services | Skillikz Cybersecurity Solutions';
  context.description = 'Discover how Skillikz Cybersecurity Solutions helped a UK-based lending institution enhance its security posture. Explore our comprehensive cybersecurity services, including DAST, SAST, risk assessment, compliance audits, and continuous monitoring.';
  context.keywords = 'Cybersecurity services, DAST, SAST, Risk assessment, Compliance audits, Continuous monitoring, Security posture, Vulnerability mitigation, Regulatory compliance, Skillikz Cybersecurity Solutions, Cyber threats, UK-based lending institution, Data protection, Risk management';
  context.seoTags();
}
