import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-blogs-details31',
  templateUrl: './blogs-details31.component.html',
  styleUrls: ['./blogs-details31.component.css']
})
export class BlogsDetails31Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails31Component) {  
  context.title = 'Good Practices in IT Product Development | Best Practices for Success';
  context.description = 'Learn about the essential best practices for successful IT product development, including agile approaches, user experience, data-driven decision-making, and collaboration';
  context.keywords = 'IT product development, agile approach, user experience, data-driven decision-making, quality assurance, collaboration, continuous improvement, customer needs, value proposition, technical expertise, competitive market';
  context.seoTags();
}
