import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-e-learning-developer',
  templateUrl: './e-learning-developer.component.html',
  styleUrls: ['./e-learning-developer.component.css']
})
export class ELearningDeveloperComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }
}

function init(context: ELearningDeveloperComponent) {  
  context.title = 'E-Learning Developer - SKILLIKZ';
  context.description = 'This is a new role for the organisation, Skillikz are now searching for a E-learning developer who is motivated to join their well-capitalised and ambitious company for the next phase of growth.';
  context.keywords = 'Software development services London, Software development services UK, Software development services US, Outsourced IT Services London, Outsourced IT Services UK, Outsourced IT Services US, Digital Transformation, Software services, Microsoft services, Salesforce Services, Digital Strategy, IT Strategy, Managed Services, IT Consultants London, Technology Consultants London, Managed IT Services, Digital Compliance, Financial Compliance, IT Audit Services, IT Engineering Services';
  context.seoTags();
}
