import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-case-study2',
  templateUrl: './case-study2.component.html',
  styleUrls: ['./case-study2.component.css']
})
export class CaseStudy2Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: CaseStudy2Component) {  
  context.title = 'Finance BDT (Business Digital Transformation) Case Study | Skillikz';
  context.description = 'Explore our successful finance industry case study, where Skillikz developed a custom recommendation engine using AI and ML techniques. Discover how we enhanced customer experiences, increased conversion rates, and optimized costs in the banking and financial services sector.';
  context.keywords = 'Finance BDT, Business digital transformation, Case study, Banking and Financial Services, Recommendation engine, AI, Machine Learning, Personalized customer experience, Conversion rates, Cost optimization, Competitive edge, Skillikz, Data-driven decisions, Corporate travel management';
  context.seoTags();
}