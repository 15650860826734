import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-blogs-details3',
  templateUrl: './blogs-details3.component.html',
  styleUrls: ['./blogs-details3.component.css']
})
export class BlogsDetails3Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails3Component) {  
  context.title = 'The Difference Between Digitisation, Digitalisation, and Digital Transformation';
  context.description = "Learn the distinct processes of digitisation, digitalisation, and digital transformation and understand how these processes can help businesses remain competitive in today's digital landscape.";
  context.keywords = 'digitisation, digitalisation, digital transformation, digital technologies, business operations, competitiveness, innovation, analogue data, digital format, customer satisfaction, revenue streams.';
  context.seoTags();
}
