import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-case-study7',
  templateUrl: './case-study7.component.html',
  styleUrls: ['./case-study7.component.css']
})
export class CaseStudy7Component extends BaseClass implements OnInit {
  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }
}

function init(context: CaseStudy7Component) {  
  context.title = 'Transforming Customer Journey in Lending Finance | Skillikz Customer Portal';
  context.description = 'Discover how Skillikz transformed the customer journey in lending finance with a user-friendly customer portal. Explore our solutions, including instant underwriting decisions, product comparison, application tracking, document management, and e-signature for faster lending processes.';
  context.keywords = 'Customer journey, Lending finance, Skillikz customer portal, Instant underwriting decisions, Product comparison, Application tracking, Document management, E-signature, User-friendly portal, Transforming lending process, Financial services sector, Tech-savvy customers, Streamline lending, Transparent lending, Operational efficiency, Financial goals';
  context.seoTags();
}
