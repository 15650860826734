import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-dot-net-developer',
  templateUrl: './dot-net-developer.component.html',
  styleUrls: ['./dot-net-developer.component.css']
})
export class DotNetDeveloperComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: DotNetDeveloperComponent) {  
  context.title = '.NET Developer - SKILLIKZ';
  context.description = 'A technical professional with hands-on experience writing quality code and understanding business in Fintech and retails industry.';
  context.keywords = 'Software development services London, Software development services UK, Software development services US, Outsourced IT Services London, Outsourced IT Services UK, Outsourced IT Services US, Digital Transformation, Software services, Microsoft services, Salesforce Services, Digital Strategy, IT Strategy, Managed Services, IT Consultants London, Technology Consultants London, Managed IT Services, Digital Compliance, Financial Compliance, IT Audit Services, IT Engineering Services';
  context.seoTags();
}