import { Component } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-health-care',
  templateUrl: './health-care.component.html',
  styleUrls: ['./health-care.component.css']
})
export class HealthCareComponent extends BaseClass {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

  override ngAfterViewInit(): void {
    //function Hidden courses here
  }
  buybundels(){
    const url = 'https://lms.skillikz.com/catalog/index/group:14';
    window.open(url, '_blank');
  }
}


function init(context: HealthCareComponent) {
  context.title = 'Health Care | Skillikz Ltd ';
  context.description = 'Access more than 35 engaging courses, including Care Certificate standards. Flexible learning, Learn 24x7, intuitive interface, interactive scenarios, and immediate practical application, CPD accredited. ';
  context.keywords = 'Skillikz, Duty of Care, Equality and Diversity, Privacy and Dignity, Fluids and Nutrition, Mental health, dementia and learning disabilities, Safeguarding adults, Basic Life Support & First Aid, Handling Information';
  context.seoTags();
}
