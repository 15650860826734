import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-mobility',
  templateUrl: './mobility.component.html',
  styleUrls: ['./mobility.component.css']
})
export class MobilityComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}
function init(context: MobilityComponent) {  
  context.title = 'Mobility Solutions | Skillikz Ltd ';
  context.description = 'Skillikz Ltd offers expert mobility solutions to help businesses create powerful and innovative mobile applications. Contact us today to learn more.';
  context.keywords = 'mobility services, mobile application development, mobile app development, mobile software development, enterprise mobile development, iOS development, Android development, Skillikz Technologies.';
  context.seoTags();
}
