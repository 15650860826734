import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-trainee-data-analyst',
  templateUrl: './trainee-data-analyst.component.html',
  styleUrls: ['./trainee-data-analyst.component.css']
})
export class TraineeDataAnalystComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: TraineeDataAnalystComponent) {  
  context.title = 'Trainee Data Analyst (Consulting Projects Division) - SKILLIKZ';
  context.description = 'Skillikz is a business consulting firm based in London offering research services to a diverse range of clients around the world.';
  context.keywords = 'Software development services London, Software development services UK, Software development services US, Outsourced IT Services London, Outsourced IT Services UK, Outsourced IT Services US, Digital Transformation, Software services, Microsoft services, Salesforce Services, Digital Strategy, IT Strategy, Managed Services, IT Consultants London, Technology Consultants London, Managed IT Services, Digital Compliance, Financial Compliance, IT Audit Services, IT Engineering Services';
  context.seoTags();
}
