import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-blogs-details1',
  templateUrl: './blogs-details1.component.html',
  styleUrls: ['./blogs-details1.component.css']
})
export class BlogsDetails1Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails1Component) {  
  context.title = 'Why Your Business Needs a Digital Transformation Strategy | Skillikz';
  context.description = 'Read our latest blog post on why your business needs a digital transformation strategy and how Skillikz can help you achieve it. Contact us to learn more';
  context.keywords = 'digital transformation strategy, business strategy, technology consulting, Skillikz, technology solutions, digital transformation benefits';
  context.seoTags();
}
