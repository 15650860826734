import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-quallity-engineer-it',
  templateUrl: './quallity-engineer-it.component.html',
  styleUrls: ['./quallity-engineer-it.component.css']
})
export class QuallityEngineerItComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: QuallityEngineerItComponent) {
  context.title = 'Quality Engineer Vacancy at Skillikz - Redefining Tech & Business Services';
  context.description = `We are looking for experienced QA professional with 5-9 years' expertise in diverse testing methodologies, automation, Agile environments, database proficiency, andCI/CD tools for efficient quality assurance as a Quality Engineer.`;
  context.keywords = 'Skillikz, Quality Engineer, QA, Testing, Automation, Agile, Database, PostgreSQL, Cassandra, Elasticsearch, Golang, Scrum, Test Types, Cucumber, Godog, Kubernetes, Docker, Rest Assured, Resty, Wiremock, Mountebank, CI/CD, Jenkins, Rally, JIRA, GIT, SVN.';
  context.seoTags();
}
