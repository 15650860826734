import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.css']
})
export class BlogsComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsComponent) {  
  context.title = 'Blogs - SKILLIKZ';
  context.description = 'Latest Trends in Business Digital Transformation, Fintech Industry & Software Development';
  context.keywords = 'Software development services London, Software development services UK, Software development services US, Outsourced IT Services London, Outsourced IT Services UK, Outsourced IT Services US, Digital Transformation, Software services, Microsoft services, Salesforce Services, Digital Strategy, IT Strategy, Managed Services, IT Consultants London, Technology Consultants London, Managed IT Services, Digital Compliance, Financial Compliance, IT Audit Services, IT Engineering Services';
  context.seoTags();
}
