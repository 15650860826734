import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-human-resources',
  templateUrl: './human-resources.component.html',
  styleUrls: ['./human-resources.component.css']
})
export class HumanResourcesComponent extends BaseClass implements OnInit {

  constructor(public router: ActivatedRoute, private location: Location) { super() }

  ngOnInit(): void {
    init(this);
    this.showLocationTab(null, 'tabOne');
    this.router.queryParams.subscribe(params => {
      if (params && params['tabName']) {
        this.showLocationTab(null, params['tabName']);
        this.location.replaceState('/human-resources');
      }
    });
  }

  override ngAfterViewInit(): void {
    //function Hidden courses here
  }
  buybundels(){
    const url = 'https://lms.skillikz.com/catalog/index/group:31';
    window.open(url, '_blank');
  }

}

function init(context: HumanResourcesComponent) {
  context.title = 'Human Resources | Skillikz Ltd';
  context.description = 'Gain insights into current employment law, rights, and responsibilities with our CPD accredited courses. Explore Safeguarding, Conflict Resolution, and more. Stay current with regulations and effective business practices through our CPD accredited courses. Designed for managers, the training offers flexibility, an intuitive interface, interactive scenarios, and immediate practical application. ';
  context.keywords = 'Skillikz, Effective Remote Working, Equality and Diversity, Conflict Resolution, Effective Remote Working, Adult at Risk - Level 3, Adults at Risk - Level 1, Adults at Risk Level 2, Equality and Diversity, Stress Awareness, HR Compliance & Wellbeing Essentials';
  context.seoTags();
}
