import { Component, OnInit } from '@angular/core';
import { BaseClass } from '../../../modules/core/base/base-class';

@Component({
  selector: 'app-blogs-details-b5523',
  templateUrl: './blogs-details-b5523.component.html',
  styleUrls: ['./blogs-details-b5523.component.css']
})
export class BlogsDetailsB5523Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit() {
    init(this);
  }
}
function init(context: BlogsDetailsB5523Component) {  
  context.title = 'Prevailing Challenges in Financial Compliance: A Market Review | Skillikz';
  context.description = 'Explore the prevailing challenges in ensuring financial compliance in the UK market and discover strategies to overcome them. Learn about digital transformation, compliance costs, cyberattacks, ESG reporting, and data privacy. Stay ahead of the evolving regulatory landscape with Skillikz, a leading technology company specializing in business digital transformation and Financial Technology (FinTech) product engineering.';
  context.keywords = 'financial compliance, UK market, challenges, digital transformation, compliance costs, cyberattacks, ESG reporting, data privacy, Skillikz, business digital transformation, FinTech product engineering.';
  context.seoTags();
}