import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-case-study5',
  templateUrl: './case-study5.component.html',
  styleUrls: ['./case-study5.component.css']
})
export class CaseStudy5Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: CaseStudy5Component) {  
  context.title = 'Cloud Support and Migration Services | Skillikz';
  context.description = 'Explore how Skillikz helped a medium-sized financial enterprise modernize its IT infrastructure and transition to the cloud. Discover our comprehensive cloud support, security, and disaster recovery solutions that ensured business continuity and ROI.';
  context.keywords = 'Cloud support, Cloud migration, Financial sector, Cloud security, Disaster recovery, ROI assessment, Skillikz, Cloud-native service, Academy, Managed Cloud Support, Azure Cloud, Data protection, Operational continuity, Cost savings, Operational efficiency';
  context.seoTags();
}
