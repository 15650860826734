import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-blogs-details40',
  templateUrl: './blogs-details40.component.html',
  styleUrls: ['./blogs-details40.component.css']
})
export class BlogsDetails40Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails40Component) {  
  context.title = 'Emerging Trends in Big Data and Analytics | Latest Insights';
  context.description = 'Stay ahead in the data-driven world by exploring the latest trends in big data and analytics. Discover augmented analytics, real-time data processing, edge computing, cloud-based analytics, data ethics and privacy, and explainable AI';
  context.keywords = 'Big data, analytics, augmented analytics, real-time data processing, edge computing, cloud-based analytics, data privacy, data ethics, explainable AI';
  context.seoTags();
}
