import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-custom-development',
  templateUrl: './custom-development.component.html',
  styleUrls: ['./custom-development.component.css']
})
export class CustomDevelopmentComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: CustomDevelopmentComponent) {  
  context.title = 'Custom Development Services | Web, Mobile, and Backend Solutions | Skillikz';
  context.description = "Transform your ideas into digital reality with Skillikz's custom development services. We offer exceptional web development, mobile app development, and robust backend solutions to empower your digital journey.";
  context.keywords = 'Custom development services, web development, mobile app development, backend solutions, digital transformation, Skillikz';
  context.seoTags();
}







