import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-blogs-details16',
  templateUrl: './blogs-details16.component.html',
  styleUrls: ['./blogs-details16.component.css']
})
export class BlogsDetails16Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails16Component) {  
  context.title = 'New Trends in Product Engineering | Latest Developments in IT Product Engineering';
  context.description = 'Discover the latest trends in IT product engineering, from Artificial Intelligence and Machine Learning to Cloud-Native Development, DevOps and Agile Development, Low-Code and No-Code Development, and IoT. Learn how these trends are transforming the software development landscape and driving business success.';
  context.keywords = 'IT product engineering, Artificial Intelligence, Machine Learning, Cloud-Native Development, DevOps, Agile Development, Low-Code Development, No-Code Development, IoT, software development, business success, software solutions, innovation';
  context.seoTags();
}
