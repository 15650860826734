import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-chaos-resiliency-test-engineer',
  templateUrl: './chaos-resiliency-test-engineer.component.html',
  styleUrls: ['./chaos-resiliency-test-engineer.component.css']
})
export class ChaosResiliencyTestEngineerComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: ChaosResiliencyTestEngineerComponent) {  
  context.title = 'Chaos & Resiliency Test Engineer - SKILLIKZ';
  context.description = 'We are looking for someone with Strong SRE & DevOps experience to help us test the resiliency of our application by applying the Chaos engineering best practices.';
  context.keywords = 'Software development services London, Software development services UK, Software development services US, Outsourced IT Services London, Outsourced IT Services UK, Outsourced IT Services US, Digital Transformation, Software services, Microsoft services, Salesforce Services, Digital Strategy, IT Strategy, Managed Services, IT Consultants London, Technology Consultants London, Managed IT Services, Digital Compliance, Financial Compliance, IT Audit Services, IT Engineering Services';
  context.seoTags();
}
