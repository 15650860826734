import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-blogs-details18',
  templateUrl: './blogs-details18.component.html',
  styleUrls: ['./blogs-details18.component.css']
})
export class BlogsDetails18Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}


function init(context: BlogsDetails18Component) {  
  context.title = 'New Trends in DevOps and Agile Development for Efficient Software Development';
  context.description = 'DevOps, Agile development, AIOps, low-code, no-code development, DevSecOps, value stream management, software development, collaboration, automation, efficiency, quality, cloud-native development.';
  context.keywords = 'DevOps and Agile Development, New trends in DevOps and Agile Development, AI in DevOps, Low-code No-code development';
  context.seoTags();
}