import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-blogs-details32',
  templateUrl: './blogs-details32.component.html',
  styleUrls: ['./blogs-details32.component.css']
})
export class BlogsDetails32Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails32Component) {  
  context.title = 'Effective Use of Data Science in Financial Underwriting | Advanced Analytics, Machine Learning, and More';
  context.description = 'Learn how data science is transforming financial underwriting by improving risk assessment, identifying fraudulent behavior, personalizing loan offers, streamlining the underwriting process, and optimizing performance';
  context.keywords = 'data science, financial underwriting, advanced analytics, machine learning, risk assessment, fraud detection, loan personalization, underwriting process, performance optimization';
  context.seoTags();
}
