import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-our-values',
  templateUrl: './our-values.component.html',
  styleUrls: ['./our-values.component.css']
})
export class OurValuesComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: OurValuesComponent) {  
  context.title = "Our Values - Discover Skillikz Ltd's Core Values and Commitment to Innovative Solutions for Business Digital Transformation | Expert IT Product Engineering Services";
  context.description = "Learn about Skillikz’ s core values: client satisfaction, innovation, quality, integrity, and teamwork. We believe in delivering technology solutions that make a positive impact on our clients' businesses";
  context.keywords = 'Skillikz, our values, core values, client satisfaction, innovation, quality, integrity, teamwork, technology solutions, UK';
  context.seoTags();
}
