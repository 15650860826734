import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-dev-ops',
  templateUrl: './dev-ops.component.html',
  styleUrls: ['./dev-ops.component.css']
})
export class DevOpsComponent extends BaseClass implements OnInit {
  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }
}

function init(context: DevOpsComponent) {  
  context.title = 'DevOps Services and Consultancy | Transforming Operations | Skillikz';
  context.description = 'Embrace DevOps with Skillikz for accelerated software development, enhanced collaboration, and operational efficiency. Explore our DevOps services, strategy, and solutions.';
  context.keywords = 'DevOps services, DevOps consultancy, software development, collaboration, operational efficiency, DevOps strategy, automation, continuous monitoring, Skillikz';
  context.seoTags();
}