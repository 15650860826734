import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-frontend',
  templateUrl: './frontend.component.html',
  styleUrls: ['./frontend.component.css']
})
export class FrontendComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}
function init(context: FrontendComponent) {  
  context.title = 'Frontend Development Services | Skillikz Ltd';
  context.description = 'Skillikz Ltd offers professional frontend development services to help businesses create beautiful and user-friendly web applications. Contact us today to learn more.';
  context.keywords = 'frontend development, frontend development services, frontend programming, frontend solutions, frontend architecture, software frontend development, user interface development, Skillikz Technologies.';
  context.seoTags();
}
