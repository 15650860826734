import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-business-digital-transformation',
  templateUrl: './business-digital-transformation.component.html',
  styleUrls: ['./business-digital-transformation.component.css']
})
export class BusinessDigitalTransformationComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}
function init(context: BusinessDigitalTransformationComponent) {  
  context.title = 'Business Digital Transformation Services | Skillikz Ltd';
  context.description = 'Skillikz Ltd offers business digital transformation services to help firms adapt to the digital age. Our services include strategy development, process optimization, and technology implementation. Contact us today to learn more.';
  context.keywords = 'Digital transformation services, Digital transformation consulting, Digital transformation solutions, Digital transformation agency, Digital transformation strategy, Digital transformation roadmap, Digital transformation roadmap consulting, Digital transformation planning, Digital transformation implementation, Digital transformation technology, Digital transformation trends, Digital transformation for small business, Digital transformation for enterprise, Digital transformation for healthcare, Digital transformation for manufacturing, Digital transformation for financial services, Digital transformation for retail, Digital transformation for government, Digital transformation for education, Digital transformation benefits, Digital transformation challenges, Skillikz, digital transformation services, software development, UK';
  context.seoTags();
}
