import { Component } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-information-security',
  templateUrl: './information-security.component.html',
  styleUrls: ['./information-security.component.css']
})
export class InformationSecurityComponent extends BaseClass {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }
  override ngAfterViewInit(): void {
    //function Hidden courses here  
  }
  buybundels(){
    const url = 'https://lms.skillikz.com/catalog/index/group:18';
    window.open(url, '_blank');
  }
}


function init(context: InformationSecurityComponent) {
  context.title = 'Information Security | Skillikz Ltd ';
  context.description = 'Strengthen business compliance with our CPD accredited cyber and data security courses, covering GDPR, Cyber Security, and more. Flexible learning, Learn 24x7, intuitive interface, interactive scenarios, and immediate practical application, CPD accredited.';
  context.keywords = 'Skillikz, Business Compliance Essentials, Conflict Resolution, Customer Service, Effective Remote Working, Cyber Security, Data Protection and GDPR level 3, GDPR Awareness Level 2';
  context.seoTags();
}