import { Component, HostListener, OnInit } from '@angular/core';
import { BaseClass } from '../../modules/core/base/base-class';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent extends BaseClass implements OnInit {

  
  public anim: any;
  public anim2: any;
  second: boolean = false;
  currentSliderIndex: number = 0;
  loadedSliders: number[] = [];

  videoSrc: string = "";
  imageSrc: string = "";
  
  @HostListener('window:resize', ['$event'])onResize(event) {
    if (window.innerWidth > 768.5){
      this.videoSrc = "assets/images/banner/home-page-banner-large.mp4";
    this.imageSrc = "assets/images/banner/home-page-banner-large.png";
    }
    else {
      this.imageSrc = "assets/images/banner/home-page-banner-small.png";
      this.videoSrc = "assets/images/banner/home-page-banner-small.mp4";
    }
  }

  constructor() {
    super()

  }

  ngOnInit(): void {
    init(this);

    if (window.innerWidth > 768.5){
      this.videoSrc = "assets/images/banner/home-page-banner-large.mp4";
    this.imageSrc = "assets/images/banner/home-page-banner-large.png";
  }
    else {
      this.videoSrc = "assets/images/banner/home-page-banner-small.mp4";
      this.imageSrc = "assets/images/banner/home-page-banner-small.png";
    }
 
  }

   

  updateDotsCss() {
    let dots = document.getElementsByClassName("dot");
    for (let i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" active", "");
    }
    dots[this.currentSliderIndex].className += " active";
  }



}



function init(context: HomeComponent) {
  context.title = 'Skillikz Ltd - Digital Transformation Consulting & Custom Software Development Services';
  context.description = 'Skillikz Ltd specializes in digital transformation consulting, custom software development, and technology consulting services. We offer services in business process automation, cloud migration, software development outsourcing, IT modernization, and more. ';
  context.keywords = 'Skillikz Technology Services, Software development services, Custom software development, Software development solutions, Software development agency, Software development consulting, Mobile app development services, Web application development services, Enterprise software development, Small business software development, Software development for startups, Software development for healthcare, Software development for finance, Software development for education, Software development for manufacturing, Software development services London, Software development services UK, Software development services US, Outsourced IT Services London, Outsourced IT Services UK, Outsourced IT Services US, Digital Transformation, Software services, Microsoft services, Salesforce Services, Digital Strategy, IT Strategy, Managed Services, IT Consultants London, Technology Consultants London, Managed IT Services, IT Audit Services, IT Engineering Services';
  context.seoTags();
}







