import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-blogs-details35',
  templateUrl: './blogs-details35.component.html',
  styleUrls: ['./blogs-details35.component.css']
})
export class BlogsDetails35Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails35Component) {  
  context.title = 'Hyperautomation in Business: Benefits, Challenges, and Examples';
  context.description = 'Learn how Hyperautomation can benefit your organization by automating complex business processes and achieving digital transformation. Discover the challenges of implementing Hyperautomation and the examples of successful applications';
  context.keywords = 'Hyperautomation, business automation, artificial intelligence, machine learning, robotic process automation, digital transformation, software development life cycle, collaboration, productivity, challenges, job displacement';
  context.seoTags();
}
