import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-sr-engineer-devops',
  templateUrl: './sr-engineer-devops.component.html',
  styleUrls: ['./sr-engineer-devops.component.css']
})

export class SrEngineerDevopsComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: SrEngineerDevopsComponent) {  
  context.title = 'Sr. Engineer DevOps Vacancy at Skillikz - Redefining Tech & Business Services';
  context.description = `We are looking for an innovative, experienced, and talented senior software engineer who will work closely with business unit and development team to develop new system capabilities as defined within the technical/ functional specifications.`;
  context.keywords = 'Skillikz, Sr. Engineer, DevOps, Senior DevOps, Infrastructure as Code, Continuous Integration, Continuous Delivery, CI/CD, Automation, Containerization, Docker, Kubernetes, Infrastructure Automation, Cloud Computing, AWS, Azure, GCP, Infrastructure Management, Scripting, Version Control, Git, Jenkins, CI/CD Pipeline, Monitoring, Log Management, Infrastructure Scaling, Security Automation, Configuration Management, Orchestration, DevOps Culture, Software Development, Infrastructure Deployment, Job Opening, Tech Jobs';
  context.seoTags();
}

