import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-case-study3',
  templateUrl: './case-study3.component.html',
  styleUrls: ['./case-study3.component.css']
})
export class CaseStudy3Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: CaseStudy3Component) {  
  context.title = 'Cloud Transformation Case Study | Skillikz';
  context.description = 'Explore our successful cloud transformation case study for a leading mortgage firm in the USA. Discover how Skillikz helped maximize performance, scalability, and security by migrating to the cloud, resulting in cost optimization and improved infrastructure.';
  context.keywords = 'Cloud transformation, Case study, Mortgage industry, Data security, Compliance, Cloud migration, Performance optimization, Scalability, Cloud benefits, Skillikz, AWS, Azure, GCP, Cybersecurity, Infrastructure cost reduction';
  context.seoTags();
}