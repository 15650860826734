import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-blogs-details23',
  templateUrl: './blogs-details23.component.html',
  styleUrls: ['./blogs-details23.component.css']
})
export class BlogsDetails23Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails23Component) {  
  context.title = 'The Latest Trends in Cloud Modernisation Strategies| Skillikz';
  context.description = 'Discover the latest trends in cloud modernisation strategies, including multi-cloud adoption, serverless computing, containerization, microservices architecture, and AI and machine learning. Learn how these trends can help your business improve its agility, reduce costs, and optimise its operations';
  context.keywords = 'cloud modernisation, multi-cloud adoption, serverless computing, containerization, microservices architecture, AI, machine learning, scalability, agility, cost savings, infrastructure, legacy applications, vendor lock-in, resilience, portability, automation, optimisation';
  context.seoTags();
}
