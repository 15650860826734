import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-technology-consulting',
  templateUrl: './technology-consulting.component.html',
  styleUrls: ['./technology-consulting.component.css']
})
export class TechnologyConsultingComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }
  
 
  showMoreText(obj:any) {
    let paragraphTag = obj.parentNode.previousElementSibling;
    let btnText = obj;
    if (obj.innerHTML === "Read less") {
        btnText.innerHTML = "Read more";
        paragraphTag.classList.remove("moreText");
    } else {
        btnText.innerHTML = "Read less";
        paragraphTag.classList.add("moreText");
    }
}

}

function init(context: TechnologyConsultingComponent) {  
  context.title = 'Consulting Services | Skillikz Ltd ';
  context.description = 'Skillikz Ltd provides technology consulting services to help firms achieve their technology goals. Our services include IT strategy development, solution architecture, and project management. Contact us today to learn more.';
  context.keywords = 'Technology consulting services, IT consulting, Digital transformation consulting, Technology strategy consulting, Management consulting services, IT management consulting, IT solutions consulting, IT consulting company, Technology consulting firm, Enterprise technology consulting, Information technology consulting, Business technology consulting, Technology implementation consulting, IT infrastructure consulting, Cloud consulting services, IT advisory services, Technology consulting for small business, Technology consulting for startups, Technology consulting for healthcare, Technology consulting for finance, Technology consulting for education, Technology consulting for government, Skillikz, technology consulting services, software development, UK';
  context.seoTags();
}
