import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-product-engineering',
  templateUrl: './product-engineering.component.html',
  styleUrls: ['./product-engineering.component.css']
})
export class ProductEngineeringComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}
function init(context: ProductEngineeringComponent) {  
  context.title = 'IT Product Engineering Services | Skillikz Ltd ';
  context.description = 'Skillikz Ltd offers comprehensive IT product engineering services including custom software development, web application development, and mobile app development. Our agile software development approach ensures that your project is delivered on time, within budget, and with high quality. Contact us today to learn more.';
  context.keywords = 'IT product engineering, Software product engineering, Product development services, Product design and engineering, Software product development, Product prototyping, Product lifecycle management, Agile product engineering, Embedded product engineering, Digital product engineering, Product engineering company, IT product development company, Outsourced product engineering, Offshore product engineering, Cloud-based product engineering, IoT product engineering, AI product engineering, ML product engineering, Product engineering consulting, Enterprise product engineering, Skillikz, product engineering services, software development, UK';
  context.seoTags();
}