import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-blogs-details37',
  templateUrl: './blogs-details37.component.html',
  styleUrls: ['./blogs-details37.component.css']
})
export class BlogsDetails37Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails37Component) {  
  context.title = 'The Future of Finance: Open Banking and Fintech';
  context.description = 'Discover how open banking and fintech are shaping the financial services industry, offering customers greater convenience, flexibility, and innovation. Learn about the benefits of open banking, such as financial data aggregation and increased competition, and explore the advantages of fintech, including personalized financial advice and lower costs';
  context.keywords = 'open banking, fintech, financial technology, APIs, PSD2 directive, financial data aggregation, competition, innovation, personalized financial advice, convenience, flexibility';
  context.seoTags();
}