import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-backend',
  templateUrl: './backend.component.html',
  styleUrls: ['./backend.component.css']
})
export class BackendComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}
function init(context: BackendComponent) {  
  context.title = 'Backend Development Services | Skillikz Ltd ';
  context.description = 'Skillikz Ltd offers professional backend development services to help businesses build robust and scalable applications. Contact us today to learn more.';
  context.keywords = 'backend development, custom backend development, backend development services, backend programming, backend solutions, backend architecture, software backend development, Skillikz Technologies.';
  context.seoTags();
}

