import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-who-we-are',
  templateUrl: './who-we-are.component.html',
  styleUrls: ['./who-we-are.component.css']
})
export class WhoWeAreComponent extends BaseClass implements OnInit{

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: WhoWeAreComponent) {  
  context.title = 'About Skillikz Ltd - Innovative Solutions for Business Digital Transformation and Customized Technology | Expert IT Product Engineering Services';
  context.description = 'Learn more about Skillikz - a UK-based software development company specializing in custom software development, mobile app development, web development, and digital transformation. Contact us today to discuss your project';
  context.keywords = 'Skillikz, software development, mobile app development, web development, digital transformation';
  context.seoTags();
}
