import { Component } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-leadership-and-management',
  templateUrl: './leadership-and-management.component.html',
  styleUrls: ['./leadership-and-management.component.css']
})
export class LeadershipAndManagementComponent extends BaseClass {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

  override ngAfterViewInit(): void {
    //function Hidden courses here
  }
  buybundels(){
    const url = 'https://lms.skillikz.com/catalog/index/group:32';
    window.open(url, '_blank');
  }
}


function init(context: LeadershipAndManagementComponent) {
  context.title = 'Leadership & Management | Skillikz Ltd ';
  context.description = 'Stay current with regulations and effective business practices for leadership & management through our CPD accredited courses designed for managers. Stay current with regulations and effective business practices through our CPD accredited courses. Designed for managers, the training offers flexibility, an intuitive interface, interactive scenarios, and immediate practical application. ';
  context.keywords = 'Skillikz, Leadership & Management, Business Compliance Essentials, Conflict Resolution, Customer Service, Effective Remote Working, Adult at Risk - Level 3, HR Compliance & Wellbeing Essentials';
  context.seoTags();
}
