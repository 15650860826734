import { Component, OnInit } from '@angular/core';
import { BaseClass } from '../../../modules/core/base/base-class';

@Component({
  selector: 'app-blogs-details-b14823',
  templateUrl: './blogs-details-b14823.component.html',
  styleUrls: ['./blogs-details-b14823.component.css']
})
export class BlogsDetailsB14823Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }
}
function init(context: BlogsDetailsB14823Component) {
  context.title = "Digital Immune System: Cybersecurity's Evolution in the Digital Era | Skillikz Blog";
  context.description = "Discover the concept of the Digital Immune System, its significance in modern cybersecurity, and why it's the future of safeguarding digital ecosystems in our ever-evolving digital age.";
  context.tags = 'Cybersecurity, Digital Immune System, Digital Protection, Data Breach Prevention, Threat Detection, Threat Intelligence, Incident Response, Continuous Monitoring, Endpoint Security, Endpoint Security, Cyber Threats, Digital Age Safety, SIEM, Security Audits, Digital Resilience, Cybersecurity Awareness, Digital Evolution';
  context.seoTags();
}