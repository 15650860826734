import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-blogs-details6',
  templateUrl: './blogs-details6.component.html',
  styleUrls: ['./blogs-details6.component.css']
})
export class BlogsDetails6Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails6Component) {  
  context.title = 'Applications of Edge Computing in Businesses | Skillikz';
  context.description = 'Explore how edge computing is being used in businesses to improve speed, accuracy, and performance for IoT, video analytics, AR and VR, retail analytics, and predictive maintenance applications';
  context.keywords = 'edge computing, IoT, video analytics, augmented reality, virtual reality, retail analytics, predictive maintenance, business operations, real-time data processing, improved performance, reduced latency, faster response times, cost savings';
  context.seoTags();
}
