import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-case-study10',
  templateUrl: './case-study10.component.html',
  styleUrls: ['./case-study10.component.css']
})
export class CaseStudy10Component extends BaseClass implements OnInit {
  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: CaseStudy10Component) {  
  context.title = 'Customer Experience Case Study | Skillikz';
  context.description = 'Discover how Skillikz transformed the online presence of a mid-sized e-commerce company, improving user experience, reducing bounce rates, and increasing conversions. Explore our customer experience (CX) case study.';
  context.keywords = 'Customer experience, CX, Case study, E-commerce, Online presence, User experience, Bounce rate reduction, Increased engagement, Higher conversions, Brand perception enhancement, Skillikz, User testing, Prototyping, Mental model analysis';
  context.seoTags();
}
