import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-blogs-details24',
  templateUrl: './blogs-details24.component.html',
  styleUrls: ['./blogs-details24.component.css']
})
export class BlogsDetails24Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails24Component) {  
  context.title = 'Latest Trends in Architecture Design for IT Product Development ';
  context.description = 'Explore the latest trends in architecture design for IT product development, including cloud-native, microservices, event-driven, serverless, and data-driven architectures. Learn how these trends can help businesses create efficient and resilient applications';
  context.keywords = 'architecture design, IT product development, cloud-native architecture, microservices architecture, event-driven architecture, serverless architecture, data-driven architecture, scalability, efficiency, resilience, agility, cost savings';
  context.seoTags();
}
