import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-cloud',
  templateUrl: './cloud.component.html',
  styleUrls: ['./cloud.component.css']
})
export class CloudComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: CloudComponent) {  
  context.title = 'Embrace the Power of Cloud Computing for Your Business | Skillikz';
  context.description = "Unlock the Future with Cloud Solutions. Explore Skillikz's comprehensive cloud services to scale, innovate, and succeed in today's digital era.";
  context.keywords = 'Cloud computing, Cloud solutions, Cloud services, Infrastructure as a Service, Platform as a Service, Software as a Service, Database as a Service, Serverless computing, Data storage, Analytics, Content delivery, Edge computing, Cloud consulting, Cloud migration, Cloud operations, Strategic analysis, Cloud strategy, Solution delivery, Continuous improvement, AWS, Amazon Web Services, Microsoft, Digital transformation';
  context.seoTags();
}


