import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-blogs-details36',
  templateUrl: './blogs-details36.component.html',
  styleUrls: ['./blogs-details36.component.css']
})
export class BlogsDetails36Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails36Component) {  
  context.title = 'What is Blockchain Technology? A Comprehensive Guide - Skillikz';
  context.description = 'Learn about the revolutionary innovation of Blockchain technology and its potential to transform industries. Discover how it works, its features, benefits, and challenges, and explore its various applications beyond cryptocurrencies.';
  context.keywords = 'Blockchain technology, distributed ledger, decentralized, trustless system, immutability, transparency, security, cryptographic algorithms, consensus mechanisms, scalability, supply chain management, digital identity verification, voting systems, cryptocurrencies';
  context.seoTags();
}
