import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-blogs-details-b19623',
  templateUrl: './blogs-details-b19623.component.html',
  styleUrls: ['./blogs-details-b19623.component.css']
})
export class BlogsDetailsB19623Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetailsB19623Component) {  
  context.title = 'Leveraging Digital Transformation Framework for Effective Technology Adoption | Skillikz';
  context.description = "Explore how businesses can effectively utilise a digital transformation framework to drive successful technology adoption. Learn about the Digital Maturity Framework, key competencies, and strategic planning for digital transformation. Empower your business with Skillikz's expertise in business digital transformation services and IT product engineering.";
  context.keywords = 'digital transformation framework, technology adoption, Digital Maturity Framework, key competencies, strategic planning, business digital transformation services, IT product engineering, Skillikz';
  context.seoTags();
}
