import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-blogs-details8',
  templateUrl: './blogs-details8.component.html',
  styleUrls: ['./blogs-details8.component.css']
})
export class BlogsDetails8Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails8Component) {  
  context.title = 'Application of Multi-Cloud Computing in Businesses: Benefits and Strategies';
  context.description = 'Learn how multi-cloud computing can benefit your business by increasing flexibility, improving resilience, reducing costs, enhancing security, and improving performance';
  context.keywords = 'Multi-Cloud Computing, Cloud Infrastructure, Benefits of Multi-Cloud, Multi-Cloud Strategies, Improved Resilience, Enhanced Security, Reduced Costs, Improved Performance';
  context.seoTags();
}
