import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-blogs-details21',
  templateUrl: './blogs-details21.component.html',
  styleUrls: ['./blogs-details21.component.css']
})
export class BlogsDetails21Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails21Component) {  
  context.title = 'Design Thinking Process in IT Product Development: A User-Centered Approach';
  context.description = 'Discover how design thinking can be a valuable tool in IT product development by prioritizing end-user needs. Learn the five-step process - empathize, define, ideate, prototype, and test - to create innovative solutions that meet user needs';
  context.keywords = 'design thinking, IT product development, end-users, user-centered approach, empathize, define, ideate, prototype, test, innovation, collaboration, problem-solving';
  context.seoTags();
}
