import { Component, OnInit } from '@angular/core';
import { BaseClass } from '../../../modules/core/base/base-class';

@Component({
  selector: 'app-blogs-details-b12723',
  templateUrl: './blogs-details-b12723.component.html',
  styleUrls: ['./blogs-details-b12723.component.css']
})
export class BlogsDetailsB12723Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}


function init(context: BlogsDetailsB12723Component) {  
  context.title = 'Empowering Business Digital Transformation: Key Enablers for SMEs | Skillikz Blogs';
  context.description = 'Discover the key enablers driving successful digital transformation in small and medium enterprises (SMEs). Learn how internal strategies, technology capabilities, skilled personnel, and agile processes, along with external partnerships, digital technologies, and competition, empower SMEs to thrive in the digital era.';
  context.keywords = '#BusinessDigitalTransformation #SMEs #BusinessGrowth #Innovation #Technology #AgileProcesses #ExternalPartnerships #DigitalCompetitiveness';
  context.seoTags();
}