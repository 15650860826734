import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-case-study9',
  templateUrl: './case-study9.component.html',
  styleUrls: ['./case-study9.component.css']
})
export class CaseStudy9Component extends BaseClass implements OnInit {
  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }
}

function init(context: CaseStudy9Component) {  
  context.title = 'Underwriting Automation | Tech Consulting Services | Skillikz';
  context.description = "Explore how Skillikz's tech consulting services revolutionized underwriting for a lending finance organization. Discover our innovative solutions, including predictive analysis, open banking data integration, and cognitive services, leading to faster loan approvals, reduced risk, and improved compliance.";
  context.keywords = 'Underwriting automation, Tech consulting services, Skillikz, Predictive analysis, Open banking data integration, Cognitive services, Efficiency gains, Risk mitigation, Compliance adherence, Consistent decision-making, Informed lending, Lending finance organization';
  context.seoTags();
}
