import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-blogs-details7',
  templateUrl: './blogs-details7.component.html',
  styleUrls: ['./blogs-details7.component.css']
})
export class BlogsDetails7Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails7Component) {  
  context.title = 'New Trends in Serverless Computing Impacting Businesses|Skillikz';
  context.description = 'Discover the latest trends in serverless computing and how they are impacting businesses. Learn about event-driven architecture, serverless machine learning, serverless databases, multi-cloud deployment, and serverless security';
  context.keywords = 'serverless computing, Function-as-a-Service (FaaS), event-driven architecture, serverless machine learning, serverless databases, multi-cloud deployment, serverless security, reduce costs, increase agility, improve scalability, business environment.';
  context.seoTags();
}
