import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-golang-software-engineer',
  templateUrl: './golang-software-engineer.component.html',
  styleUrls: ['./golang-software-engineer.component.css']
})

export class GolangSoftwareEngineerComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: GolangSoftwareEngineerComponent) {  
  context.title = 'Golang Software Engineer Vacancy at Skillikz - Redefining Tech & Business Services';
  context.description = `We are looking for Golang Developer to join our team, who will work closely with development team to develop new system capabilities as defined within the technical/ functional specifications.`;
  context.keywords = 'Skillikz, Golang Software Engineer, Go Developer, Backend Engineer, Software Development, Programming, Go Language, Golang, RESTful APIs, Microservices, Web Development, Distributed Systems, Concurrency, Algorithms, Data Structures, Database Design, SQL, NoSQL, Git, Agile Development, Scrum, Test-Driven Development, Continuous Integration, Deployment, DevOps, Linux, Docker, Kubernetes, Cloud Computing, AWS, GCP, Azure, Problem Solving, Communication Skills, Teamwork, Time Management, Full-time, Part-time, Remote, On-site, Job Opportunities, Hiring, Tech Industry, IT Jobs.';
  context.seoTags();
}
